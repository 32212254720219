<template>
  <div class="wrapper">
    <div class="operate-wrapper">
      <img src="@/assets/img/error-oa@2x .png" alt />
      <div class="text">{{ oaErrorMsg }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    oaErrorMsg() {
      return this.$store.state.oaErrorMsg;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  background: #ffffff;
}

.operate-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  img {
    margin-top: 180px;
    width: 100px;
    height: 96px;
  }

  .text {
    margin: 18px 0 38px;
    font-size: $font-15;
    font-weight: 500;
    color: $color-999;
  }
}
</style>
